<template>
  <div class="group-retrieval4">
    <div class="group-head">
      <el-form ref="form" :inline="true">
        <el-form-item style="width: 30%;">
          <el-input
            clearable
            size="small"
            style="width:100%"
            v-model="searchForm.title"
            placeholder="频道名"
          ></el-input>
        </el-form-item>
        <el-form-item style="width: 30%;">
          <el-input
            clearable
            size="small"
            style="width:100%"
            v-model="searchForm.channelSummary"
            placeholder="频道简介"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            class="el-icon-search"
            size="small"
            @click="searchFun"
            :loading="loading"
          >
            查询
          </el-button>
          <el-button
            :loading="loading"
            class="el-icon-refresh"
            size="small"
            @click="refresh"
          >
            刷新
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="group-content" v-loading="loading">
      <el-table
        style="margin-bottom:10px"
        :row-style="{ height: 0 + 'px' }"
        :cell-style="{ padding: '5px 0' }"
        header-row-class-name="tableheader"
        :data="tableData"
        border
        :height="windowsHeight - 220"
      >
        <el-table-column prop="title" label="频道名">
          <template slot-scope="scope">
            <span v-html="scope.row.title"></span>
          </template>
        </el-table-column>

        <el-table-column label="频道简介" prop="channelSummary">
          <template slot-scope="scope">
            <span v-html="scope.row.channelSummary"></span>
          </template>
        </el-table-column>
        <el-table-column
          prop="channelLanguage"
          label="频道语言"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="numbers"
          label="成员数量"
          width="150"
        ></el-table-column>
        <el-table-column label="操作" width="150" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              style="color:#046DBB"
              @click="retrieval(scope.row)"
            >
              同步到提交群信息
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="total"
        :page-size="limit"
        :currentPage="current"
        :pagerCount="5"
        :background="false"
        :sizesArr="[20, 50, 100]"
        @currentChange="currentChange"
        @sizeChange="sizeChange"
      />
    </div>
  </div>
</template>

<script>
import {
  telegramChannelSummaryApi,
  channelSummarySubmitApi
} from '@/api/retrieval'
export default {
  name: 'SearchPosts4',
  data() {
    return {
      searchForm: {
        channelSummary: '',
        title: ''
      },
      loading: false,
      total: 0,
      current: 1,
      limit: 20,
      tableData: []
    }
  },
  computed: {
    windowsHeight() {
      return this.$store.state.windowData.height
    }
  },
  created() {
    this.getData()
  },
  methods: {
    // 提交
    retrieval(row) {
      this.$confirm(`确认同步， 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          channelSummarySubmitApi({
            id: row.id
          }).then(data => {
            this.$message.success('同步成功，请到提交群信息功能查看采集结果！')
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    searchFun() {
      this.getData()
    },
    currentChange(val) {
      this.current = val
      this.getData()
    },

    sizeChange(val) {
      this.current = 1
      this.limit = val
      this.getData()
    },
    getData() {
      this.loading = true
      telegramChannelSummaryApi(this.current, this.limit, this.searchForm).then(
        data => {
          this.total = data.all_count
          this.tableData = data.items
          this.loading = false
        }
      )
    },
    refresh() {
      this.searchForm.title = ''
      this.searchForm.channelSummary = ''

      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
.group-retrieval4 {
  width: 100%;
  height: calc(100vh - 79px);
  overflow: hidden;
  background: rgb(240, 242, 245);
  padding: 10px;
  .group-head {
    background: white;
    padding: 5px;
    white-space: nowrap;
    ::v-deep .el-form-item {
      margin-bottom: 0;
      .el-form-item__content {
        width: 100%;
      }
    }
    overflow: hidden;
    border: 1px solid #e5e5e5;
    box-shadow: 0 1px 3px 0 #d8d9d9;
    border-radius: 5px;
  }
  .group-content {
    background: white;
    margin-top: 5px;
    background: white;
    padding: 10px;
    width: 100%;
    height: calc(100% - 55px);
    overflow: hidden;
    border: 1px solid #e5e5e5;
    box-shadow: 0 1px 3px 0 #d8d9d9;
    border-radius: 5px;
    .table-photo {
      width: 29px;
      height: 29px;
      border-radius: 5px;
    }
  }
  ::v-deep .tableheader th {
    background: rgb(243, 247, 249);
    padding: 8px 0;
  }
}
.el-link.el-link--danger.is-underline:hover:after,
.el-link.el-link--danger:after {
  border: 0;
}
.el-link.el-link--danger {
  cursor: default;
}
</style>
