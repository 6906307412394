import request from '@/plugins/axios'

// 账号检索
export function telegramUsersSearch(current, limit, data) {
  return request({
    url: `/telegramUsersSearch/${current}/${limit}`,
    method: 'post',
    data
  })
}

// 关键词检索
export function message(current, limit, data) {
  return request({
    url: `/message/${current}/${limit}`,
    method: 'post',
    data
  })
}

// 群组检索
export function telegramChannels(current, limit, data) {
  return request({
    url: `/telegramChannels/${current}/${limit}`,
    method: 'post',
    data
  })
}

// 群组采集
export function collection(id, tips) {
  return request({
    url: `/telegramChannels/${id}`,
    method: 'put',
    data: {
      tips: tips
    }
  })
}

// 定位到群组
export function messageLocation(channelId, messageId) {
  return request({
    url: `/message/location/${channelId}/${messageId}`
  })
}

//
// 群组索引分页
export function telegramChannelSummaryApi(current, limit, data) {
  return request({
    url: `/telegramChannelSummary/${current}/${limit}`,
    method: 'post',
    data
  })
}
// 群组索引提交
export function channelSummarySubmitApi(data) {
  return request({
    url: `/telegramChannelSummary`,
    method: 'post',
    data
  })
}
